import React from "react";
import {
	Container,
	Text,
	Title,
	Paper,
	Box,
	Anchor,
	CopyButton,
	ActionIcon,
	Tooltip,
	Group,
} from "@mantine/core";
import { IconCheck, IconCopy, IconSettings } from "@tabler/icons";
import { IntegrationsTable } from "./ui/IntegrationsTable";
import { db } from "./firebase";
import { collection, onSnapshot } from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { useApi } from "./context/APIContext";

export default function Settings() {
	const [integrations, setIntegrations] = React.useState([]);
	const { emailAlias } = useApi();
	const { companyId } = useAuth();

	React.useLayoutEffect(() => {
		const unsub = onSnapshot(
			collection(db, "companies", companyId, "api-keys"),
			(querySnapshot) => {
				const arr = [];
				querySnapshot.forEach((doc) => {
					arr.push(doc.data());
				});
				setIntegrations(arr);
			}
		);

		return unsub;
	}, [companyId]);

	return (
		<Container size="xl" sx={{ marginTop: "1rem" }}>
			<Paper shadow="sm" radius="md" p="xl">
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						marginBottom: "2rem",
					}}
				>
					<IconSettings size={32} color="teal" />
					<Title mb={0} ml=".5rem">
						Settings
					</Title>
				</Box>
				<Title mt={40} mb={5} order={2}>
					Integrations
				</Title>
				<Text size="sm">
					Integrate Plannerific with your favorite tool by using our built-in
					connectors.
				</Text>
				<Title mt={40} mb={5} order={2}>
					Inbox email address
				</Title>
				<Text size="sm" mb={15}>
					You can use this unique email address to send tasks from your mailbox
					to Plannerific. <Anchor href="">Learn more</Anchor>
				</Text>
				<Group
					sx={{
						border: "1px solid #F1F3F5",
						borderRadius: "10px",
						padding: ".2rem .5rem",
						width: 500,
						backgroundColor: "#F1F3F5",
					}}
					spacing="xs"
				>
					<Text color="teal" size="sm">
						{emailAlias}
					</Text>
					<CopyButton value={emailAlias} timeout={2000}>
						{({ copied, copy }) => (
							<Tooltip
								label={copied ? "Copied" : "Copy"}
								withArrow
								position="right"
							>
								<ActionIcon
									radius={"md"}
									color={copied ? "teal" : "gray"}
									onClick={copy}
								>
									{copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
								</ActionIcon>
							</Tooltip>
						)}
					</CopyButton>
				</Group>
				<Title mt={40} mb={5} order={2}>
					API
				</Title>
				<Text size="sm" mb={15}>
					Use our REST API to connect other services to your scheduling
					environment. Register a new integration below to get started.{" "}
					<Anchor href="">View documentation</Anchor>
				</Text>
				<IntegrationsTable data={integrations} />
			</Paper>
		</Container>
	);
}
