import { createStyles, Text, Box, Title, Group, Tooltip } from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons";
import { Draggable } from "react-beautiful-dnd";

const useStyles = createStyles((theme) => ({
	item: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: theme.radius.md,
		paddingLeft: ".5rem",
		width: "100%",
		transition: "box-shadow 150ms ease, transform 100ms ease",
		marginBottom: ".5rem",
		"&:hover": {
			boxShadow: `${theme.shadows.md} !important`,
			transform: "scale(1.05)",
		},
	},
	text: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
}));

export default function WeekCard({
	item,
	index,
	color,
	isNotAvailable,
	resourceName,
	onClick,
}) {
	const { classes } = useStyles();

	return (
		<Draggable key={item.id} draggableId={item.id} index={index}>
			{(e, snapshot) => (
				<Box
					className={classes.item}
					sx={(theme) => ({
						backgroundColor: theme.colors[color][5],
						border: isNotAvailable && `3px solid ${theme.colors.red[8]}`,
					})}
					ref={e.innerRef}
					{...e.draggableProps}
					{...e.dragHandleProps}
					onClick={onClick}
				>
					<Box className={classes.text}>
						<Group spacing="xs">
							{isNotAvailable && (
								<Tooltip
									label={`${resourceName} is unavailable`}
									withArrow
									radius="md"
									openDelay={150}
									styles={{
										tooltip: {
											fontSize: ".7rem",
										},
									}}
								>
									<Box mb={-5} mr={-5}>
										<IconAlertCircle color="white" size={16} />
									</Box>
								</Tooltip>
							)}
							<Title color="white" order={6} lineClamp={2}>
								{item.title}
							</Title>
						</Group>
						<Text size="xs" color="white">
							{new Date(item.startDate).toLocaleTimeString([], {
								hour: "2-digit",
								minute: "2-digit",
							})}{" "}
							-{" "}
							{new Date(item.endDate).toLocaleTimeString([], {
								hour: "2-digit",
								minute: "2-digit",
							})}
						</Text>
					</Box>
				</Box>
			)}
		</Draggable>
	);
}
