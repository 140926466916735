import React from "react";
import {
	Box,
	Button,
	ScrollArea,
	Table,
	SegmentedControl,
	Grid,
	Avatar,
	Text,
	useMantineTheme,
} from "@mantine/core";
import { IconChevronLeft, IconChevronRight, IconCalendar } from "@tabler/icons";
import { DatePicker } from "@mantine/dates";
import WeekCard from "../ui/WeekCard";
import { Droppable } from "react-beautiful-dnd";
import dayjs from "dayjs";
var isoWeek = require("dayjs/plugin/isoWeek");
dayjs.extend(isoWeek);

const date = new Date();

function WeekSelector({
	selectedDate,
	setSelectedDate,
	setWeeknumber,
	weeknumber,
}) {
	const theme = useMantineTheme();

	return (
		<Button.Group sx={{ marginBottom: "1rem" }}>
			<Button
				variant="outline"
				radius="md"
				size="xs"
				onClick={() => {
					const startOfWeek = dayjs().isoWeek(weeknumber).startOf();
					const startOfLastWeek = dayjs(startOfWeek).subtract(1, "week");
					const lastWeek = dayjs(startOfLastWeek).isoWeek();
					setSelectedDate(startOfLastWeek.toDate());

					setWeeknumber(lastWeek);
				}}
			>
				<IconChevronLeft />
			</Button>
			<DatePicker
				value={selectedDate}
				onChange={(e) => {
					setSelectedDate(e);
					const week = dayjs(e).isoWeek();
					setWeeknumber(week);
				}}
				inputFormat="DD MMM YYYY"
				clearable={false}
				size="xs"
				radius="none"
				styles={{
					input: {
						width: 120,
					},
				}}
				dayStyle={(e) =>
					e.toLocaleDateString("be-nl", {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
					}) ===
					date.toLocaleDateString("be-nl", {
						day: "2-digit",
						month: "2-digit",
						year: "2-digit",
					})
						? {
								backgroundColor: theme.colors.red[5],
								color: "white",
								borderRadius: theme.radius.md,
						  }
						: null
				}
				icon={<IconCalendar size={16} />}
			/>
			<Button
				variant="outline"
				radius="md"
				size="xs"
				onClick={() => {
					const startOfWeek = dayjs().isoWeek(weeknumber).startOf();
					const startOfNextWeek = dayjs(startOfWeek).add(1, "week");
					const nextWeek = dayjs(startOfNextWeek).isoWeek();
					setSelectedDate(startOfNextWeek.toDate());
					setWeeknumber(nextWeek);
				}}
			>
				<IconChevronRight />
			</Button>
		</Button.Group>
	);
}

export default function WeekGrid({
	schedule,
	setSchedule,
	resources,
	setAppointment,
}) {
	const [view, setView] = React.useState("week");
	const [weeknumber, setWeeknumber] = React.useState(1);
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	// hooks
	const theme = useMantineTheme();

	React.useLayoutEffect(() => {
		//const thisWeek = moment().isoWeek();
		const thisWeek = dayjs().isoWeek();
		setWeeknumber(thisWeek);
		setSelectedDate(date);
	}, []);

	function getWeekDaysByWeekNumber(weeknumber) {
		var date = dayjs().isoWeek(weeknumber).startOf("isoWeek"),
			weeklength = 7,
			result = [];
		while (weeklength--) {
			result.push({
				dateFormat: date.format("dd DD/MM/YY"),
				date: date.toDate(),
			});
			const nextDay = date.add(1, "day");
			date = nextDay;
		}
		return result;
	}

	let weekdayshortname = getWeekDaysByWeekNumber(weeknumber).map((day, i) => {
		const isToday = date.toLocaleDateString() === day.date.toLocaleDateString();

		const isSelected =
			selectedDate.toLocaleDateString() === day.date.toLocaleDateString();

		let color;
		if (isSelected) {
			color = "teal";
		}
		if (isToday) {
			color = "red";
		}

		const highlight = isSelected + isToday;

		return (
			<th
				key={i}
				style={{
					borderRight: "1px solid #dee2e6",
					borderLeft: "1px solid #dee2e6",
					textAlign: "center",
					// backgroundColor: dateColor,
					transition: "all 150ms ease-in-out",
				}}
			>
				<Text
					sx={{
						backgroundColor: highlight && theme.colors[color][5],
						borderRadius: highlight && theme.radius.md,
						color: highlight && "white",
					}}
				>
					{day.dateFormat}
				</Text>
			</th>
		);
	});

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				paddingTop: "1rem",
			}}
		>
			<Grid grow p="0 1rem">
				<Grid.Col span={4}></Grid.Col>
				<Grid.Col span={4} sx={{ display: "flex", justifyContent: "center" }}>
					<SegmentedControl
						value={view}
						onChange={setView}
						radius="md"
						sx={{ marginBottom: "1rem" }}
						data={[
							{ label: "Day", value: "day" },
							{ label: "Week", value: "week" },
							{ label: "Month", value: "month" },
							{ label: "List", value: "list" },
						]}
					/>
				</Grid.Col>
				<Grid.Col
					span={4}
					sx={{
						display: "flex",
						justifyContent: "flex-end",
					}}
				>
					<WeekSelector
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						setWeeknumber={setWeeknumber}
						weeknumber={weeknumber}
					/>
				</Grid.Col>
			</Grid>
			<ScrollArea style={{ height: "100%", width: "100%", padding: "1rem" }}>
				<Table verticalSpacing="md">
					<thead>
						<tr>
							<th>Week {weeknumber}</th>
							{weekdayshortname}
						</tr>
					</thead>
					<tbody>
						{resources.map((resource, i) => {
							return (
								<tr
									key={i}
									style={{
										borderBottom:
											i === resources.length - 1 && "1px solid #dee2e6",
										verticalAlign: "top",
									}}
								>
									<td>
										<Box
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Avatar color={resource.color} radius="xl" size={28}>
												{resource.avatar}
											</Avatar>
											{resource.name}
										</Box>
									</td>
									{getWeekDaysByWeekNumber(weeknumber).map((day, i) => {
										const isoDay = dayjs(day.date).isoWeekday();

										// get unavailable dates

										const isNotAvailable = resource.availability?.some(
											(availability) =>
												availability.data.filter(
													(e) => e.isoDay === isoDay && e.allday === false
												).length > 0
										);

										const filtered = schedule.filter(
											(e) =>
												e.assignee.includes(resource.id) &&
												dayjs(e.startDate).format("DD/MM/YYYY") ===
													dayjs(day.date).format("DD/MM/YYYY")
										);

										return (
											<td
												key={i}
												style={{
													borderRight: "1px solid #dee2e6",
													borderLeft: "1px solid #dee2e6",
													//backgroundColor: dateColor,
													transition: "all 150ms ease-in-out",
													padding: 0,
													width: "12%",
													background: isNotAvailable && theme.colors.gray[2],
												}}
											>
												<Droppable
													droppableId={"grid&" + day.date + "&" + resource.id}
													isDropDisabled={isNotAvailable}
													//type="ASBESTINVENTARIS"
												>
													{(provided, snapshot) => {
														return (
															<div
																ref={provided.innerRef}
																style={{
																	backgroundColor: snapshot.isDraggingOver
																		? "#96f2d7"
																		: null,
																	minHeight: "100px",
																	transition: "all 150ms ease-in-out",
																	padding: ".5rem",
																}}
																{...provided.droppableProps}
															>
																{filtered.map((e, i) => {
																	return (
																		<WeekCard
																			isNotAvailable={isNotAvailable}
																			resourceName={resource.name}
																			item={e}
																			index={i}
																			key={i}
																			color={resource.color}
																			onClick={() => setAppointment(e)}
																		/>
																	);
																})}
																<div style={{ display: "none" }}>
																	{provided.placeholder}
																</div>
															</div>
														);
													}}
												</Droppable>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</ScrollArea>
		</Box>
	);
}
