import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MantineProvider, Global } from "@mantine/core";
import { AuthProvider } from "./context/AuthContext";
import { APIProvider } from "./context/APIContext";
import { NotificationsProvider } from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<AuthProvider>
		<APIProvider>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					colors: {},
					primaryColor: "teal",
				}}
			>
				<NotificationsProvider>
					<App />
				</NotificationsProvider>
			</MantineProvider>
		</APIProvider>
	</AuthProvider>
);
