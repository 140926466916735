import React from "react";
import {
	Table,
	Group,
	Text,
	ActionIcon,
	ScrollArea,
	Button,
	Avatar,
	Tooltip,
	ThemeIcon,
	Anchor,
	CopyButton,
	Popover,
} from "@mantine/core";
import {
	IconPencil,
	IconTrash,
	IconPlus,
	IconUser,
	IconCar,
	IconMapPin,
	IconTool,
	IconCopy,
	IconCheck,
} from "@tabler/icons";
import { useAuth } from "../context/AuthContext";
import { useApi } from "../context/APIContext";
export function ResourcesTable({ data, openDrawer, setSelected }) {
	const { companyId } = useAuth();

	const { deleteResource } = useApi();
	const rows = data.map((item, i) => {
		let type;
		switch (item.type) {
			case "Person":
				type = (
					<Group spacing="xs">
						<ThemeIcon variant="light" size={18} radius="md">
							<IconUser />
						</ThemeIcon>
						<Text size="sm" color="dimmed">
							{item.type}
						</Text>
					</Group>
				);
				break;
			case "Car":
				type = (
					<Group spacing="xs">
						<ThemeIcon variant="light" size={18} radius="md">
							<IconCar />
						</ThemeIcon>
						<Text size="sm" color="dimmed">
							{item.type}
						</Text>
					</Group>
				);
				break;
			case "Meeting room":
				type = (
					<Group spacing="xs">
						<ThemeIcon variant="light" size={18} radius="md">
							<IconMapPin />
						</ThemeIcon>
						<Text size="sm" color="dimmed">
							{item.type}
						</Text>
					</Group>
				);
				break;
			case "Tool":
				type = (
					<Group spacing="xs">
						<ThemeIcon variant="light" size={18} radius="md">
							<IconTool />
						</ThemeIcon>
						<Text size="sm" color="dimmed">
							{item.type}
						</Text>
					</Group>
				);
				break;
			default:
				type = (
					<Text size="sm" weight={500}>
						-
					</Text>
				);
				break;
		}

		return (
			<tr key={i}>
				<td>
					<Group spacing="sm">
						<Avatar
							size={26}
							src={item.avatar}
							radius={26}
							color={item.color}
						/>
						<Text size="sm" weight={500}>
							{item.name}
						</Text>
					</Group>
				</td>

				<td>
					{item.email !== undefined ? (
						<Anchor href={`mailto:${item.email}`} color="dimmed">
							{item.email}
						</Anchor>
					) : (
						<Text size="sm" color="dimmed">
							-
						</Text>
					)}
				</td>
				<td>{type}</td>
				<td>
					<Group spacing={0} position="right">
						<CopyButton
							value={`https://plannerific.app/ical/${companyId}/${item.id}`}
							timeout={2000}
						>
							{({ copied, copy }) => (
								<Tooltip
									label={copied ? "Copied!" : "Copy iCal link"}
									position="top"
								>
									<ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
										{copied ? <IconCheck size={16} /> : <IconCopy size={16} />}
									</ActionIcon>
								</Tooltip>
							)}
						</CopyButton>
						<Tooltip label="Edit">
							<ActionIcon onClick={() => setSelected(item)}>
								<IconPencil size={16} stroke={1.5} />
							</ActionIcon>
						</Tooltip>
						<Popover position="bottom-end" withArrow shadow="md">
							<Popover.Target>
								<Tooltip label="Delete">
									<ActionIcon color="red">
										<IconTrash size={16} stroke={1.5} />
									</ActionIcon>
								</Tooltip>
							</Popover.Target>
							<Popover.Dropdown>
								<Group>
									<Text size="sm">Are you sure?</Text>
									<Tooltip withArrow label="Delete">
										<ActionIcon
											variant="filled"
											color="red"
											size="sm"
											onClick={() => deleteResource(item.id)}
										>
											<IconTrash size={14} />
										</ActionIcon>
									</Tooltip>
								</Group>
							</Popover.Dropdown>
						</Popover>
					</Group>
				</td>
			</tr>
		);
	});

	return (
		<ScrollArea>
			<Table sx={{ minWidth: 800 }} verticalSpacing="sm">
				<thead>
					<tr>
						<th style={{ width: "30%" }}>Name</th>
						<th style={{ width: "30%" }}>Email</th>
						<th style={{ width: "20%" }}>Type</th>
						<th style={{ textAlign: "end" }}>
							<Button
								radius="md"
								leftIcon={<IconPlus size={14} />}
								size="xs"
								onClick={() => openDrawer()}
							>
								New resource
							</Button>
						</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</ScrollArea>
	);
}
