import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/storage";

const app = initializeApp({
	apiKey: "AIzaSyDMMXHfnGDDRWrqdRD5fdhkQfGWCUzY-R4",
	authDomain: "scheduler-38e0b.firebaseapp.com",
	projectId: "scheduler-38e0b",
	storageBucket: "scheduler-38e0b.appspot.com",
	messagingSenderId: "1812851755",
	appId: "1:1812851755:web:d8d59748a8fad1289f4e6e",
});

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);

if (window.location.hostname === "localhost") {
	console.log(
		"testing locally -- hitting local functions and firestore emulators"
	);
	//	connectAuthEmulator(auth, "http://localhost:9099");
	connectFunctionsEmulator(functions, "localhost", 5001);
	//connectFirestoreEmulator(db, "localhost", 8080);
}

export { auth, db, functions, storage };
export default app;
