import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Login from "./Login";
import Schedule from "./Schedule";
import Settings from "./Settings";
import Workspace from "./Workspace";
//import RequireAuth from "./Routes/RequireAuth";
import Resources from "./Resources";
import { useAuth } from "./context/AuthContext";
export default function App() {
	const { currentUser } = useAuth();
	return (
		<BrowserRouter>
			<Workspace>
				<Routes>
					{!currentUser && <Route path="/" element={<Login />} />}
					{currentUser && (
						<>
							<Route path="/" index element={<Home />} />
							<Route path="/schedule" element={<Schedule />} />
							<Route path="/resources" element={<Resources />} />
							<Route path="/settings" element={<Settings />} />
						</>
					)}
				</Routes>
			</Workspace>
		</BrowserRouter>
	);
}
