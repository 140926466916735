import React from "react";
import {
	Box,
	Drawer,
	Title,
	Accordion,
	useMantineTheme,
	TextInput,
	MultiSelect,
	Stack,
	Group,
	Text,
	Divider,
	Autocomplete,
	Checkbox,
	Button,
	Badge,
	Menu,
	ActionIcon,
} from "@mantine/core";
import {
	IconAlertCircle,
	IconBriefcase,
	IconCalendar,
	IconCalendarPlus,
	IconCheck,
	IconChevronDown,
	IconClock,
	IconInbox,
	IconMap2,
	IconTrash,
	IconUsers,
} from "@tabler/icons";
import ScheduleCard from "./ui/ScheduleCard";
import WeekGrid from "./Scheduler/Grid";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useForm } from "@mantine/form";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useApi } from "./context/APIContext";
import { useReward } from "react-rewards";
import { showNotification } from "@mantine/notifications";

import dayjs from "dayjs";
import _ from "lodash";
const date = new Date();

export function EventDrawer(props) {
	const theme = useMantineTheme();
	const { resources, addToSchedule, removeFromSchedule, unscheduleTask } =
		useApi();

	const appointment = props.appointment;

	const form = useForm({
		initialValues: {
			title: "",
			assignee: "",
			startDate: "",
			endDate: "",
			client: "",
			address: "",
			sendSMS: false,
			sendEmail: false,
			id: "",
			type: "",
			creationDate: "",
		},
	});

	const resourceList = resources.map((e) => {
		return { value: e.id, label: e.name };
	});

	React.useEffect(() => {
		const startDate = dayjs(appointment.startDate).set("hour", 8).toDate();
		const endDate = dayjs(startDate).add(2, "hours").toDate();
		form.setValues({
			title: appointment.title,
			assignee: appointment.assignee,
			startDate: startDate,
			endDate: endDate,
			id: appointment.id,
			type: appointment.type,
			creationDate: appointment.creationDate,
		});
	}, [appointment]);

	return (
		<Drawer
			opened={props.opened}
			onClose={() => {
				props.setOpened(false);
				props.setAppointment({});
			}}
			title="Schedule"
			padding="xl"
			size="xl"
			position="right"
			styles={{
				title: {
					fontSize: "34px",
					fontWeight: "bold",
				},
			}}
			overlayColor={
				theme.colorScheme === "dark"
					? theme.colors.dark[9]
					: theme.colors.gray[4]
			}
			overlayOpacity={0.55}
			closeOnClickOutside={false}
		>
			<form
				onSubmit={form.onSubmit((values) => {
					addToSchedule(values);
					props.setOpened(false);
				})}
			>
				<Stack justify="flex-start" spacing="sm">
					<TextInput
						required
						data-autofocus
						label="Title"
						radius="md"
						placeholder="My scheduled event"
						{...form.getInputProps("title")}
					/>

					<Group grow spacing="sm" mb="1rem">
						<Stack justify="flex-start" spacing="xs">
							<Text size="sm" weight={500} color="#212529">
								Start
							</Text>
							<DatePicker
								inputFormat="DD MMM YYYY"
								clearable={false}
								radius="md"
								dayStyle={(e) =>
									e.toLocaleDateString("be-nl", {
										day: "2-digit",
										month: "2-digit",
										year: "2-digit",
									}) ===
									date.toLocaleDateString("be-nl", {
										day: "2-digit",
										month: "2-digit",
										year: "2-digit",
									})
										? { backgroundColor: "#f1f3f5", color: "black" }
										: null
								}
								icon={<IconCalendar size={16} />}
								{...form.getInputProps("startDate")}
							/>
							<TimeInput
								//	defaultValue={new Date()}
								label="Start time"
								radius="md"
								amLabel="am"
								pmLabel="pm"
								withAsterisk
								clearable
								{...form.getInputProps("startDate")}
								icon={<IconClock size={16} />}
							/>
						</Stack>
						<Stack justify="flex-start" spacing="xs">
							<Text size="sm" weight={500} color="#212529">
								End
							</Text>
							<DatePicker
								inputFormat="DD MMM YYYY"
								clearable={false}
								radius="md"
								dayStyle={(e) =>
									e.toLocaleDateString("be-nl", {
										day: "2-digit",
										month: "2-digit",
										year: "2-digit",
									}) ===
									date.toLocaleDateString("be-nl", {
										day: "2-digit",
										month: "2-digit",
										year: "2-digit",
									})
										? { backgroundColor: "#f1f3f5", color: "black" }
										: null
								}
								icon={<IconCalendar size={16} />}
								{...form.getInputProps("endDate")}
							/>
							<TimeInput
								//defaultValue={new Date()}
								label="Start time"
								radius="md"
								amLabel="am"
								pmLabel="pm"
								withAsterisk
								clearable
								{...form.getInputProps("endDate")}
								icon={<IconClock size={16} />}
							/>
						</Stack>
					</Group>
					<Divider color="teal" />
					<MultiSelect
						label="Resource(s)"
						icon={<IconUsers size={16} />}
						placeholder="Select one or more resources"
						styles={{
							value: {
								backgroundColor: "#e6fcf5",
							},
						}}
						{...form.getInputProps("assignee")}
						data={resourceList}
						radius="md"
						nothingFound="Nothing found"
					/>
					<Autocomplete
						label="Client"
						radius="md"
						icon={<IconBriefcase size={16} />}
						placeholder="Start typing to search..."
						data={["React", "Angular", "Svelte", "Vue"]}
						{...form.getInputProps("client")}
					/>
					{!_.isEmpty(form.values.client) && (
						<>
							<Checkbox
								value="sms"
								label="SMS"
								{...form.getInputProps("sendSMS", { type: "checkbox" })}
							/>
							<Checkbox
								value="email"
								label="Email"
								{...form.getInputProps("sendEmail", { type: "checkbox" })}
							/>
						</>
					)}
					<Autocomplete
						label="Address"
						icon={<IconMap2 size={16} />}
						radius="md"
						placeholder="Start typing to search..."
						data={["React", "Angular", "Svelte", "Vue"]}
						{...form.getInputProps("address")}
					/>
				</Stack>
				<Group position="right" mt={20}>
					<Group noWrap spacing={0}>
						<Button
							sx={{
								borderTopRightRadius: 0,
								borderBottomRightRadius: 0,
							}}
							leftIcon={<IconCalendarPlus size={14} />}
							type="submit"
						>
							{_.isEmpty(form.values.assignee) ? "Save to Inbox" : "Schedule"}
						</Button>
						<Menu position="bottom-end" withArrow>
							<Menu.Target>
								<ActionIcon
									variant="filled"
									color={theme.primaryColor}
									size={36}
									sx={{
										borderTopLeftRadius: 0,
										borderBottomLeftRadius: 0,
										borderLeft: `1px solid ${
											theme.colorScheme === "dark"
												? theme.colors.dark[7]
												: theme.white
										}`,
									}}
								>
									<IconChevronDown size={16} stroke={1.5} />
								</ActionIcon>
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									icon={<IconInbox size={16} stroke={1.5} />}
									onClick={() => {
										unscheduleTask(form.values.id, form.values);
										props.setOpened(false);
									}}
								>
									Unschedule
								</Menu.Item>
								<Menu.Item
									color="red"
									icon={<IconTrash size={16} stroke={1.5} />}
									onClick={() => {
										removeFromSchedule(form.values.id);
										props.setOpened(false);
									}}
								>
									Delete
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Group>
				</Group>
			</form>
		</Drawer>
	);
}

export default function Schedule() {
	const [toSchedule, setToSchedule] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [assigned, setAssigned] = React.useState([]);
	const [opened, setOpened] = React.useState(false);
	const [appointment, setAppointment] = React.useState({});
	const [defaultOpen, setDefaultOpen] = React.useState("");
	const { resources, inbox, schedule, updateTask, unscheduleTask } = useApi();

	const { reward, isAnimating } = useReward("rewardId", "confetti", {
		elementCount: 250,
		spread: 90,
		angle: 90,
	});

	React.useEffect(() => {
		setToSchedule(inbox);
		const filterCategories = [...new Set(inbox.map((item) => item.type))];
		setCategories(filterCategories);
		setDefaultOpen(filterCategories[0]);
	}, [inbox]);

	const onDragEnd = async (result) => {
		const { destination, source, draggableId } = result;

		if (!destination) {
			return;
		}

		if (destination.droppableId.substring(0, 4) === "grid") {
			//change of cell
			if (source.droppableId.substring(0, 4) === "grid") {
				const sourceSplit = source.droppableId.split("&");
				const sourceDate = sourceSplit[1];

				const destinationSplit = destination.droppableId.split("&");
				const destinationDate = destinationSplit[1];
				const destinationAssignee = destinationSplit[2];

				const difference = dayjs(sourceDate).diff(dayjs(destinationDate));

				const task = { ...schedule.find((e) => e.id === draggableId) };

				if (task.assignee.length > 1) {
					console.log("Error: multiple assignees");
					showNotification({
						icon: <IconAlertCircle size={18} />,
						color: "red",
						title: `Cannot move task ${task.title}`,
						message: "Multiple resources are assigned to this task",
					});
				} else {
					task.assignee = [destinationAssignee];
					task.startDate = dayjs(task.startDate)
						.subtract(difference, "milliseconds")
						.toDate();
					task.endDate = dayjs(task.endDate)
						.subtract(difference, "milliseconds")
						.toDate();

					await updateTask(draggableId, task).then(() =>
						showNotification({
							icon: <IconCheck size={18} />,
							color: "teal",
							title: `Succesfully rescheduled '${task.title}'`,
							message: `"${task.title}" was rescheduled to ${dayjs(
								task.startDate
							)
								.toDate()
								.toLocaleDateString()}`,
						})
					);
				}
			} else {
				// new entry on board

				const split = destination.droppableId.split("&");
				const date = split[1];
				const assignee = split[2];

				const task = { ...toSchedule.find((e) => e.id === draggableId) };

				task.assignee = [assignee];
				task.startDate = dayjs(date).toDate();

				setAppointment(task);

				const taskIndex = toSchedule.findIndex((e) => e.id === draggableId);
				let newArr = [...assigned];
				newArr.push(task);

				let newOriginArr = [...toSchedule];
				newOriginArr.splice(taskIndex, 1);

				setAssigned(newArr);
				setToSchedule(newOriginArr);
			}
		}

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
	};

	React.useEffect(() => {
		if (toSchedule.length === 0) {
			reward();
		}
	}, [toSchedule.length]);

	React.useEffect(() => {
		if (!_.isEmpty(appointment)) {
			setOpened(true);
		}
	}, [appointment]);

	return (
		<>
			<DragDropContext onDragEnd={onDragEnd}>
				<Box sx={{ width: "100%", height: "100%", display: "flex" }}>
					<Box
						sx={{
							backgroundColor: "white",
							height: "100%",
							width: 300,
							margin: 0,
							padding: "1rem",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								marginBottom: "2rem",
							}}
						>
							<IconInbox size={32} color="teal" />

							<Title style={{ marginLeft: ".5rem" }}>Inbox</Title>
						</Box>
						{toSchedule.length === 0 && (
							<Stack align="center">
								<Text size={36} id="rewardId">
									🎉
								</Text>
								<Text>All done!</Text>
							</Stack>
						)}
						<Accordion
							//	defaultValue={defaultOpen}
							value={defaultOpen}
							onChange={setDefaultOpen}
							unstyled
							styles={{
								control: {
									background: "none",
									border: "none",
									display: "flex",
									flexDirection: "row-reverse",
									justifyContent: "space-between",
									width: "100%",
									paddingTop: ".5rem",
									paddingBottom: ".5rem",
								},
								item: {
									// styles added to all items
									backgroundColor: "#fff",
									paddingBottom: "2rem",
								},

								chevron: {
									// styles added to chevron when it should rotate
									"&[data-rotate]": {
										transform: "rotate(-90deg)",
									},
								},
							}}
						>
							{categories?.map((category, i) => {
								const items = toSchedule.filter((e) => e.type === category);
								if (items.length > 0) {
									return (
										<Accordion.Item value={category} key={i}>
											<Accordion.Control>
												<Group spacing="xs">
													{category.charAt(0).toUpperCase() + category.slice(1)}{" "}
													{items.length > 0 && <Badge>{items.length}</Badge>}
												</Group>
											</Accordion.Control>
											<Accordion.Panel>
												<Droppable droppableId={category}>
													{(provided, snapshot) => (
														<div
															ref={provided.innerRef}
															{...provided.droppableProps}
														>
															{items.map((item, index) => {
																return (
																	<ScheduleCard
																		key={index}
																		item={item}
																		index={index}
																		onClick={() => setAppointment(item)}
																	/>
																);
															})}
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											</Accordion.Panel>
										</Accordion.Item>
									);
								}
							})}
						</Accordion>
					</Box>
					<WeekGrid
						schedule={schedule}
						setSchedule={setAssigned}
						resources={resources}
						setAppointment={setAppointment}
					/>
				</Box>
			</DragDropContext>
			<EventDrawer
				opened={opened}
				setOpened={setOpened}
				appointment={appointment}
				setAppointment={setAppointment}
			/>
		</>
	);
}
