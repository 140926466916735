import { useState } from "react";
import {
	AppShell,
	Navbar,
	Center,
	Tooltip,
	UnstyledButton,
	createStyles,
	Stack,
} from "@mantine/core";
import {
	IconHome2,
	IconCalendarStats,
	IconUser,
	IconSettings,
	IconLogout,
} from "@tabler/icons";
import { useAuth } from "./context/AuthContext";
import { auth } from "./firebase";

import { useNavigate } from "react-router-dom";

const useStyles = createStyles((theme) => ({
	link: {
		width: 50,
		height: 50,
		borderRadius: theme.radius.md,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: theme.white,
		opacity: 0.85,

		"&:hover": {
			opacity: 1,
			backgroundColor: theme.fn.lighten(
				theme.fn.variant({ variant: "filled", color: theme.primaryColor })
					.background,
				0.1
			),
		},
	},

	active: {
		opacity: 1,
		"&, &:hover": {
			backgroundColor: theme.fn.lighten(
				theme.fn.variant({ variant: "filled", color: theme.primaryColor })
					.background,
				0.3
			),
		},
	},
}));

function NavbarLink({ icon: Icon, label, active, onClick }) {
	const { classes, cx } = useStyles();
	return (
		<Tooltip label={label} position="right" withArrow transitionDuration={150}>
			<UnstyledButton
				onClick={onClick}
				className={cx(classes.link, { [classes.active]: active })}
			>
				<Icon stroke={1.5} />
			</UnstyledButton>
		</Tooltip>
	);
}

const mockdata = [
	{ icon: IconHome2, label: "Home", path: "/" },
	{ icon: IconCalendarStats, label: "Schedule", path: "/schedule" },
	{ icon: IconUser, label: "Resources", path: "/resources" },
	{ icon: IconSettings, label: "Settings", path: "/settings" },
];

export function NavbarMinimalColored() {
	const [active, setActive] = useState(0);

	const { logout } = useAuth();
	const navigate = useNavigate();
	const links = mockdata.map((link, index) => (
		<NavbarLink
			{...link}
			key={link.label}
			active={index === active}
			onClick={() => {
				setActive(index);
				navigate(link.path);
			}}
		/>
	));

	if (auth.currentUser !== null) {
		return (
			<Navbar
				height="100%"
				width={{ base: 80 }}
				p="md"
				sx={(theme) => ({
					backgroundColor: theme.fn.variant({
						variant: "filled",
						color: theme.primaryColor,
					}).background,
				})}
			>
				<Center></Center>
				<Navbar.Section grow mt={50}>
					<Stack justify="center" spacing={0}>
						{links}
					</Stack>
				</Navbar.Section>
				<Navbar.Section>
					<Stack justify="center" spacing={0}>
						<NavbarLink
							icon={IconLogout}
							label="Logout"
							onClick={() => logout()}
						/>
					</Stack>
				</Navbar.Section>
			</Navbar>
		);
	}
}

export default function Workspace({ children }) {
	return (
		<AppShell
			navbar={<NavbarMinimalColored />}
			styles={(theme) => ({
				main: {
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.dark[8]
							: theme.colors.gray[0],
					paddingTop: 0,
					paddingBottom: 0,
					marginLeft: "-1rem",
				},
			})}
		>
			{children}
		</AppShell>
	);
}
