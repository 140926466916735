import React, { useContext } from "react";
import { auth, db } from "../firebase";
import {
	collection,
	doc,
	onSnapshot,
	deleteDoc,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import _ from "lodash";
const APIContext = React.createContext();

export function useApi() {
	return useContext(APIContext);
}

export function APIProvider({ children }) {
	const [resources, setResources] = React.useState([]);
	const [schedule, setSchedule] = React.useState([]);
	const [emailAlias, setEmailAlias] = React.useState([]);
	const [resourceTypes, setResourceTypes] = React.useState([]);
	const { companyId } = useAuth();

	const [inbox, setInbox] = React.useState([]);

	React.useLayoutEffect(() => {
		if (auth.currentUser !== null) {
			const unsub = onSnapshot(
				collection(db, "companies", companyId, "resources"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						arr.push({
							...doc.data(),
							id: doc.id,
						});
					});
					setResources(arr);
				}
			);

			const unsubInbox = onSnapshot(
				collection(db, "companies", companyId, "inbox"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						arr.push({
							...doc.data(),
							id: doc.id,
						});
					});
					// sort the array. Newer inbox items always appear on top
					const sorted = arr.sort((a, b) => b.creationDate - a.creationDate);
					setInbox(sorted);
				}
			);

			const unsubSchedule = onSnapshot(
				collection(db, "companies", companyId, "schedule"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						arr.push({
							...doc.data(),
							id: doc.id,
							startDate: doc.data().startDate.toDate(),
							endDate: doc.data().endDate.toDate(),
						});
					});
					// sort the array. Newer inbox items always appear on top
					setSchedule(arr);
				}
			);
			const unsubCompany = onSnapshot(
				doc(db, "companies", companyId),
				(doc) => {
					setEmailAlias(`${doc.data().emailAlias}@inbox.plannerific.app`);
				}
			);
			const unsubSettings = onSnapshot(
				collection(db, "general", "settings", "resourceTypes"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						arr.push({
							...doc.data(),
							id: doc.id,
						});
					});
					// sort the array. Newer inbox items always appear on top
					setResourceTypes(arr);
				}
			);

			return () => {
				unsub();
				unsubInbox();
				unsubCompany();
				unsubSettings();
				unsubSchedule();
			};
		} else {
			setResources([]);
		}
	}, [auth.currentUser, companyId]);

	const addToSchedule = async (values) => {
		if (_.isEmpty(values.assignee)) {
			const docRef = doc(db, "companies", companyId, "inbox", values.id);
			console.log(values);
			await updateDoc(docRef, {
				assignee: [],
				type: values.type,
				id: values.id,
				title: values.title,
				creationDate: values.creationDate,
			});
		} else {
			await setDoc(doc(db, "companies", companyId, "schedule", values.id), {
				...values,
			});
			await removeFromInbox(values.id);
		}
	};

	const removeFromSchedule = async (id) => {
		const docRef = doc(db, "companies", companyId, "schedule", id);

		await deleteDoc(docRef);
	};

	const updateTask = async (id, values) => {
		const docRef = doc(db, "companies", companyId, "schedule", id);
		await updateDoc(docRef, {
			...values,
		});
	};

	const unscheduleTask = async (id, values) => {
		const docRefSchedule = doc(db, "companies", companyId, "schedule", id);
		const docRefInbox = doc(db, "companies", companyId, "inbox", id);
		await deleteDoc(docRefSchedule);
		await setDoc(docRefInbox, {
			title: values.title,
			id: values.id,
			creationDate: new Date(),
			type: values.type,
		});
	};

	const removeFromInbox = async (id) => {
		const docRef = doc(db, "companies", companyId, "inbox", id);
		await deleteDoc(docRef);
	};

	const deleteResource = async (id) => {
		const resourceRef = doc(db, "companies", companyId, "resources", id);
		await deleteDoc(resourceRef);
	};

	const value = {
		resources,
		inbox,
		emailAlias,
		resourceTypes,
		addToSchedule,
		schedule,
		updateTask,
		deleteResource,
		removeFromSchedule,
		unscheduleTask,
	};
	return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
}
