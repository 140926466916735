import React, { useContext, useState } from "react";
import { auth } from "../firebase";
import {
	onAuthStateChanged,
	getIdTokenResult,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [companyId, setCompanyId] = useState("");
	const [loading, setLoading] = useState(true);

	async function logout() {
		signOut(auth).then(() => {
			window.location.pathname = "/";
		});
	}

	async function login(email, password) {
		return await signInWithEmailAndPassword(auth, email, password);
	}

	React.useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
			if (currentUser) {
				setCurrentUser(currentUser);
				await getIdTokenResult(currentUser).then((idTokenResult) => {
					if (idTokenResult) {
						setCompanyId(idTokenResult.claims.companyId);
					}
				});
			}
		});

		return () => {
			unsubscribe();
		};
	}, [currentUser]);

	const value = {
		currentUser,
		logout,
		companyId,
		login,
	};
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
