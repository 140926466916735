import { createStyles, Text, Divider, Box, Title } from "@mantine/core";
import { Draggable } from "react-beautiful-dnd";

const useStyles = createStyles((theme) => ({
	item: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		borderRadius: theme.radius.md,
		height: 90,
		padding: ".5rem",
		width: "100%",
		backgroundColor: theme.colors.red[5],
		transition: "box-shadow 150ms ease, transform 100ms ease",
		marginBottom: ".5rem",
		"&:hover": {
			boxShadow: `${theme.shadows.md} !important`,
			transform: "scale(1.05)",
		},
	},
	text: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		paddingLeft: ".75rem",
	},
}));

export default function ScheduleCard({ item, index, onClick }) {
	const { classes } = useStyles();

	return (
		<Draggable key={item.id} draggableId={item.id} index={index}>
			{(e, snapshot) => (
				<Box
					className={classes.item}
					ref={e.innerRef}
					{...e.draggableProps}
					{...e.dragHandleProps}
					onClick={onClick}
				>
					<Divider
						size="xl"
						orientation="vertical"
						color="white"
						sx={{ borderRadius: "15px" }}
					/>
					<Box className={classes.text}>
						<Title color="white" order={6} lineClamp={2}>
							{item.title}
						</Title>
						<Text size="xs" color="white">
							{item.address}
						</Text>
					</Box>
				</Box>
			)}
		</Draggable>
	);
}
