import React from "react";
import {
	ColorSwatch,
	Popover,
	useMantineTheme,
	SimpleGrid,
	ActionIcon,
} from "@mantine/core";

export default function Colorpicker({ value, onChange }) {
	const theme = useMantineTheme();
	const [color, setColor] = React.useState("red");
	const [opened, setOpened] = React.useState(false);

	React.useEffect(() => {
		if (value !== undefined) {
			setColor(value);
		}
	}, []);

	React.useEffect(() => {
		onChange(color);
	}, [color]);

	const swatches = Object.keys(theme.colors).map((color) => (
		<ActionIcon
			onClick={() => {
				setColor(color);
				setOpened(false);
			}}
		>
			<ColorSwatch key={color} color={theme.colors[color][6]} />
		</ActionIcon>
	));

	return (
		<Popover
			width={200}
			position="right-start"
			withArrow
			shadow="md"
			radius="md"
			opened={opened}
			onChange={setOpened}
		>
			<Popover.Target>
				<ActionIcon onClick={() => setOpened((o) => !o)}>
					<ColorSwatch color={theme.colors[color][5]} />
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown>
				<SimpleGrid cols={5}>{swatches}</SimpleGrid>
			</Popover.Dropdown>
		</Popover>
	);
}
