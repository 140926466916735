import React from "react";
import {
	Container,
	Text,
	Title,
	Paper,
	Box,
	Anchor,
	Drawer,
	useMantineTheme,
	TextInput,
	Select,
	Stack,
	Group,
	ScrollArea,
	ThemeIcon,
	Button,
	Divider,
	ActionIcon,
	Tooltip,
} from "@mantine/core";
import { useForm } from "@mantine/form";

import {
	IconCar,
	IconCheck,
	IconClock,
	IconMapPin,
	IconPlus,
	IconTool,
	IconTrash,
	IconUser,
	IconUsers,
} from "@tabler/icons";
import { useApi } from "./context/APIContext";
import { ResourcesTable } from "./ui/ResourcesTable";
import { TimeInput } from "@mantine/dates";
import dayjs from "dayjs";
import { db } from "./firebase";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { useAuth } from "./context/AuthContext";
import { showNotification } from "@mantine/notifications";
import Colorpicker from "./ui/Colorpicker";
import _ from "lodash";

export default function Resources() {
	const { resources } = useApi();
	const [drawer, openDrawer] = React.useState(false);
	const [selected, setSelected] = React.useState({});

	React.useEffect(() => {
		if (!_.isEmpty(selected)) {
			openDrawer(true);
		}
	}, [selected]);
	return (
		<>
			<Container size="xl" sx={{ marginTop: "1rem" }}>
				<Paper shadow="sm" radius="md" p="xl">
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							marginBottom: "2rem",
						}}
					>
						<IconUsers size={32} color="teal" />
						<Title mb={0} ml=".5rem">
							Resources
						</Title>
					</Box>
					<Text size="sm">
						Create or modify resources below. Resources can be anything (humans,
						machines, meetingrooms, etc.). After creation, a resource will be
						available as a target in your schedule.{" "}
						<Anchor href="">View documentation</Anchor>
					</Text>
					<ResourcesTable
						data={resources}
						openDrawer={() => openDrawer(true)}
						setSelected={setSelected}
					/>
				</Paper>
			</Container>
			<ResourceDrawer
				opened={drawer}
				setOpened={openDrawer}
				selected={selected}
				setSelected={setSelected}
			/>
		</>
	);
}

const SelectItem = React.forwardRef(
	({ image, label, description, ...others }, ref) => {
		let type;
		switch (label) {
			case "Person":
				type = (
					<ThemeIcon variant="light" size={18} radius="md">
						<IconUser />
					</ThemeIcon>
				);
				break;
			case "Car":
				type = (
					<ThemeIcon variant="light" size={18} radius="md">
						<IconCar />
					</ThemeIcon>
				);
				break;
			case "Meeting room":
				type = (
					<ThemeIcon variant="light" size={18} radius="md">
						<IconMapPin />
					</ThemeIcon>
				);
				break;
			case "Tool":
				type = (
					<ThemeIcon variant="light" size={18} radius="md">
						<IconTool />
					</ThemeIcon>
				);
				break;
			default:
				type = (
					<Text size="sm" weight={500}>
						-
					</Text>
				);
				break;
		}

		return (
			<div ref={ref} {...others}>
				<Group noWrap>
					{type}

					<div>
						<Text size="sm">{label}</Text>
					</div>
				</Group>
			</div>
		);
	}
);

const demoavailability = [
	{
		id: "default",
		start: dayjs().hour(8).minute(0).second(0).toDate(),
		end: dayjs().hour(17).minute(0).second(0).toDate(),
		data: [
			{
				id: "monday",
				isoDay: 1,
				allday: true,
			},
			{
				id: "tuesday",
				isoDay: 2,
				allday: true,
			},
			{
				id: "wednesday",
				isoDay: 3,
				allday: true,
			},
			{
				id: "thursday",
				isoDay: 4,
				allday: true,
			},
			{
				id: "friday",
				isoDay: 5,
				allday: true,
			},
			{
				id: "saturday",
				isoDay: 6,
				allday: false,
			},
			{
				id: "sunday",
				isoDay: 7,
				allday: false,
			},
		],
	},
];

export function ResourceDrawer(props) {
	const theme = useMantineTheme();
	const { resourceTypes } = useApi();
	const [availability, setAvailability] = React.useState([]);
	const [isUpdate, setIsUpdate] = React.useState(false);
	const [loading, setLoading] = React.useState(false);

	const { companyId } = useAuth();

	// set default availability of new resource
	React.useEffect(() => {
		if (!isUpdate) {
			setAvailability(demoavailability);
		}
	}, [isUpdate]);

	// set the availability of the selected resource if the selected object is not empty
	React.useEffect(() => {
		if (!_.isEmpty(props.selected)) {
			form.setValues(props.selected);
			setIsUpdate(true);
			setAvailability(props.selected.availability);
		}
	}, [props.selected]);

	// form handler
	const form = useForm({
		initialValues: {
			name: "",
			type: "",
			color: "green",
			//avatar: "",
		},

		validate: {
			name: (value) =>
				value.length < 2 ? "Name must have at least 2 letters" : null,
			type: (value) => (value < 1 ? "You must select a type" : null),
		},
	});

	// add a new availability entry to the array
	const addAvailability = () => {
		const arr = [...availability];

		arr.push({
			id: arr.length + 1,
			start: dayjs().hour(8).minute(0).toDate(),
			end: dayjs().hour(17).minute(0).toDate(),
			data: [
				{
					id: "monday",
					isoDay: 1,
					allday: false,
				},
				{
					id: "tuesday",
					isoDay: 2,
					allday: false,
				},
				{
					id: "wednesday",
					isoDay: 3,
					allday: false,
				},
				{
					id: "thursday",
					isoDay: 4,
					allday: false,
				},
				{
					id: "friday",
					isoDay: 5,
					allday: false,
				},
				{
					id: "saturday",
					isoDay: 6,
					allday: false,
				},
				{
					id: "sunday",
					isoDay: 7,
					allday: false,
				},
			],
		});
		setAvailability(arr);
	};

	const removeAvailability = (i) => {
		const arr = [...availability];

		arr.splice(i, 1);
		setAvailability(arr);
	};

	const updateAvailability = (objectId, day, value) => {
		const arr = [...availability];
		const week = arr.findIndex((e) => e.id === objectId);
		const time = arr[week].data.findIndex((e) => e.id === day);
		arr[week].data[time].allday = value;

		setAvailability(arr);
	};

	const handleSubmit = async (values) => {
		setLoading(true);
		const resourceRef = collection(db, "companies", companyId, "resources");

		if (!isUpdate) {
			await addDoc(resourceRef, {
				...values,
				availability: availability,
			})
				.then(() => {
					setLoading(false);

					props.setOpened(false);
					showNotification({
						icon: <IconCheck size={18} />,
						color: "teal",
						title: `Resource ${values.name} created`,
						message: "Your resource was created successfully",
					});
					form.reset();
				})
				.catch(() => setLoading(false));
		} else {
			await updateDoc(doc(db, "companies", companyId, "resources", values.id), {
				...values,
				availability: availability,
			})
				.then(() => {
					setLoading(false);

					props.setOpened(false);
					showNotification({
						icon: <IconCheck size={18} />,
						color: "teal",
						title: `Resource ${values.name} updated`,
						message: "Your resource was updated successfully",
					});
					form.reset();
				})
				.catch(() => setLoading(false));
		}
	};
	return (
		<Drawer
			opened={props.opened}
			onClose={() => {
				props.setOpened(false);
				form.reset();
				props.setSelected({});
				setIsUpdate(false);
			}}
			title={
				<Title>
					<IconUsers color="teal" />{" "}
					{!isUpdate ? "New resource" : "Update resource"}
				</Title>
			}
			padding="xl"
			size="xl"
			position="right"
			overlayColor={
				theme.colorScheme === "dark"
					? theme.colors.dark[9]
					: theme.colors.gray[4]
			}
			overlayOpacity={0.55}
		>
			<form onSubmit={form.onSubmit(handleSubmit)} style={{ height: "100%" }}>
				<Stack justify="space-between">
					<TextInput
						withAsterisk
						label="Name"
						placeholder="Jon Johnsson"
						{...form.getInputProps("name")}
					/>
					<Select
						label="Resource type"
						placeholder="Pick one"
						nothingFound="No options"
						withAsterisk
						data={resourceTypes}
						itemComponent={SelectItem}
						filter={(value, item) =>
							item.label.toLowerCase().includes(value.toLowerCase().trim())
						}
						{...form.getInputProps("type")}
					/>
					<Box>
						<Text size="sm" weight={500}>
							Color
						</Text>
						<Colorpicker {...form.getInputProps("color")} />
					</Box>
					<Divider />
					<Box>
						<Text size="sm" weight={500}>
							Availability
						</Text>
						<ScrollArea
							style={{
								height: 450,
							}}
						>
							{availability.map((data, i) => {
								return (
									<Box
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											marginBottom: 20,
										}}
										key={i}
									>
										<Group spacing="xs">
											<Button.Group>
												{data.data.map((day, i) => {
													let variant;
													switch (day.allday) {
														case true:
															variant = "filled";
															break;

														default:
															variant = "outline";
															break;
													}
													return (
														<Button
															uppercase
															variant={variant}
															onClick={() =>
																updateAvailability(data.id, day.id, !day.allday)
															}
															key={i}
														>
															{day.id.substring(0, 1)}
														</Button>
													);
												})}
											</Button.Group>
											<Group spacing="xs">
												<TimeInput
													defaultValue={
														!isUpdate ? data.start : data.start.toDate()
													}
													label="Start time"
													radius="md"
													withAsterisk
													icon={<IconClock size={16} />}
												/>
												<TimeInput
													defaultValue={
														!isUpdate ? data.end : data.end.toDate()
													}
													label="End time"
													radius="md"
													withAsterisk
													icon={<IconClock size={16} />}
												/>
												<Select
													label="Repeat"
													defaultValue="weekly"
													disabled={i === 0}
													withAsterisk={i > 0}
													sx={{ width: 115 }}
													data={[
														{ value: "weekly", label: "Weekly" },
														{ value: "biweekly", label: "Biweekly" },
														{ value: "triweekly", label: "Triweekly" },
														{ value: "monthly", label: "Monthly" },
													]}
												/>{" "}
											</Group>
										</Group>
										<Box
											sx={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											{i > 0 && (
												<Tooltip
													label="Remove custom availability"
													withArrow
													position="left"
												>
													<ActionIcon
														mr={30}
														color="red"
														radius="xl"
														onClick={() => removeAvailability(i)}
													>
														<IconTrash />
													</ActionIcon>
												</Tooltip>
											)}
											{i + 1 === availability.length && (
												<Tooltip
													label="Add custom availability"
													withArrow
													position="left"
												>
													<ActionIcon
														mr={30}
														color="teal"
														radius="xl"
														onClick={() => addAvailability()}
													>
														<IconPlus />
													</ActionIcon>
												</Tooltip>
											)}
										</Box>
									</Box>
								);
							})}
						</ScrollArea>
					</Box>
					<Group position="right">
						<Button type="submit" loading={loading}>
							{!isUpdate ? "Submit" : "Update"}
						</Button>
					</Group>
				</Stack>
			</form>
		</Drawer>
	);
}
