import React from "react";
import {
	Table,
	Group,
	Text,
	ActionIcon,
	ScrollArea,
	Button,
	PasswordInput,
	TextInput,
	Tooltip,
	Transition,
	Progress,
} from "@mantine/core";
import {
	IconPencil,
	IconTrash,
	IconPlus,
	IconDeviceFloppy,
	IconX,
	IconCheck,
} from "@tabler/icons";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase";
import { showNotification } from "@mantine/notifications";

export function IntegrationsTable({ data }) {
	const [newIntegration, setNewIntegration] = React.useState(false);
	const [newIntegrationName, setNewIntegrationName] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const createNewApp = () => {
		setLoading(true);

		const create = httpsCallable(functions, "createNewApp");
		create({
			title: newIntegrationName,
		}).then(() => {
			setNewIntegration(false);
			setLoading(false);
			showNotification({
				icon: <IconCheck size={18} />,
				color: "teal",
				title: `Integration ${newIntegrationName} created`,
				message: "Your integration was created successfully",
			});
			setNewIntegrationName("");
		});
	};

	const deleteApp = (id) => {
		setLoading(true);
		const remove = httpsCallable(functions, "deleteApp");
		remove({
			id: id,
		}).then(() => {
			setNewIntegration(false);
			setNewIntegrationName("");
			setLoading(false);
			showNotification({
				icon: <IconTrash size={18} />,
				color: "red",
				title: "Integration deleted",
				message: "Your integration was deleted successfully",
			});
		});
	};

	const NewIntegration = ({ style }) => {
		return (
			<tr style={style}>
				<td>
					<TextInput
						placeholder="Choose an integration name..."
						autoFocus
						disabled={loading}
						radius="md"
						size="xs"
						value={newIntegrationName}
						onChange={(event) =>
							setNewIntegrationName(event.currentTarget.value)
						}
					/>
				</td>
				<td>
					<Text size="sm" color="gray">
						Id will be available after saving
					</Text>
				</td>
				<td>
					<Group spacing={0} position="right">
						<Tooltip label="Save">
							<ActionIcon onClick={() => createNewApp()}>
								<IconDeviceFloppy size={16} stroke={1.5} />
							</ActionIcon>
						</Tooltip>
						<Tooltip label="Cancel">
							<ActionIcon
								color="red"
								onClick={() => {
									setNewIntegration(false);
									setNewIntegrationName("");
								}}
							>
								<IconX size={16} stroke={1.5} />
							</ActionIcon>
						</Tooltip>
					</Group>
				</td>
			</tr>
		);
	};
	const rows = data.map((item, i) => (
		<tr key={i}>
			<td>
				<Text size="sm" weight={500}>
					{item.title}
				</Text>
			</td>

			<td>
				<Text size="sm" color="dimmed">
					<PasswordInput
						placeholder="************************************"
						variant="filled"
						radius="md"
						size="xs"
						withAsterisk
						readOnly
						value={item.id}
					/>
				</Text>
			</td>
			<td>
				<Group spacing={0} position="right">
					<Tooltip label="Edit name">
						<ActionIcon>
							<IconPencil size={16} stroke={1.5} />
						</ActionIcon>
					</Tooltip>
					<Tooltip label="Delete">
						<ActionIcon color="red" onClick={() => deleteApp(item.id)}>
							<IconTrash size={16} stroke={1.5} />
						</ActionIcon>
					</Tooltip>
				</Group>
			</td>
		</tr>
	));

	return (
		<ScrollArea>
			<Table sx={{ minWidth: 800 }} verticalSpacing="sm">
				<thead>
					<tr>
						<th style={{ width: "30%" }}>Name</th>
						<th style={{ width: "50%" }}>Integration ID</th>
						<th style={{ textAlign: "end" }}>
							<Button
								radius="md"
								leftIcon={<IconPlus size={14} />}
								size="xs"
								onClick={() => setNewIntegration(true)}
							>
								New integration
							</Button>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr style={{ border: "none" }}>
						<td colSpan={3} style={{ padding: 0, border: "none" }}>
							{loading && (
								<Progress
									color="teal"
									radius="md"
									size="xs"
									value={100}
									animate
								/>
							)}
						</td>
					</tr>
					<Transition
						mounted={newIntegration}
						transition="scale-y"
						duration={250}
						timingFunction="ease"
					>
						{(styles) => <NewIntegration style={styles} />}
					</Transition>
					{rows}
				</tbody>
			</Table>
		</ScrollArea>
	);
}
