import React from "react";

import {
	Paper,
	createStyles,
	TextInput,
	PasswordInput,
	Checkbox,
	Button,
	Title,
	Text,
	Anchor,
} from "@mantine/core";
import { useAuth } from "./context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
const useStyles = createStyles((theme) => ({
	wrapper: {
		minHeight: "100%",
		backgroundSize: "cover",
		backgroundImage:
			"url(https://images.unsplash.com/photo-1515847049296-a281d6401047?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80)",
	},

	form: {
		borderRight: `1px solid ${
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
		}`,
		minHeight: "100vh",
		maxWidth: 450,
		paddingTop: 80,

		[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
			maxWidth: "100%",
		},
	},

	title: {
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
	},

	logo: {
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		width: 120,
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

export default function Login() {
	const { classes } = useStyles();
	const { login } = useAuth();

	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");

	let navigate = useNavigate();
	let location = useLocation();

	let from = location.state?.from?.pathname || "/";

	return (
		<div className={classes.wrapper}>
			<Paper className={classes.form} radius={0} p={30}>
				<Title
					order={2}
					className={classes.title}
					align="center"
					mt="md"
					mb={50}
				>
					Welcome back to Plannerific!
				</Title>

				<TextInput
					label="Email address"
					placeholder="hello@gmail.com"
					size="md"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<PasswordInput
					label="Password"
					size="md"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<Button
					fullWidth
					mt="xl"
					size="md"
					onClick={async () => {
						await login(email, password);
						navigate(from, { replace: true });
					}}
				>
					Login
				</Button>

				<Text align="center" mt="md">
					Don&apos;t have an account?{" "}
					<Anchor
						href="#"
						weight={700}
						onClick={(event) => event.preventDefault()}
					>
						Register
					</Anchor>
				</Text>
			</Paper>
		</div>
	);
}
