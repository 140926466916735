import React from "react";
import { Container, Text, Title, Paper, Button } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons";
import { useNavigate } from "react-router-dom";

import { useApi } from "./context/APIContext";

export default function Home() {
	const navigate = useNavigate();
	const { inbox } = useApi();
	return (
		<Container size="xl" sx={{ marginTop: "1rem" }}>
			<Paper shadow="sm" radius="md" p="xl">
				<Title mb={20}>Welcome back Jesse!</Title>
				<Text>
					There are <b>{inbox.length}</b> open appointments to schedule!
					<Button
						variant="subtle"
						radius="md"
						compact
						rightIcon={<IconArrowRight size={14} />}
						onClick={() => navigate("/schedule")}
					>
						Schedule now
					</Button>
				</Text>
			</Paper>
		</Container>
	);
}
